'use client'
import Toggle from '@/components/toggle'
import { trackPageView } from '@/util/analytics'
import { useRouter } from 'next/navigation'
import { useContext } from 'react'
import { SearchTypeContext } from '../search-type-context'
import { SearchType } from '../types'

export const getPathname = (route: string, term: string, type: SearchType): string => {
    const gifUrl = `${term
        .replace(/stickers|sticker|transparent|clips/gi, '')
        .trim()
        .replace(/\s+/g, '-')}`
    return `${route}/${gifUrl}${type === 'gifs' ? `` : `-${type}`}${document.location.search}`
}

type Props = { className?: string; initialSearchTerm: string; routerType: SearchType }

const SearchToggle = ({ initialSearchTerm, routerType, className }: Props) => {
    const router = useRouter()
    const { setSearchType: setType } = useContext(SearchTypeContext)
    const contentTypes = ['gifs', 'stickers', 'clips'] as const
    return (
        <Toggle
            onClick={(index: number) => {
                setType(contentTypes[index] as (typeof contentTypes)[number])
            }}
            onTransitionEnd={(index: number) => {
                const nextType = contentTypes[index]
                const nextPathname = getPathname(`/search`, initialSearchTerm, nextType)
                router.replace(nextPathname)
                setTimeout(() => {
                    trackPageView(nextPathname)
                }, 50)
            }}
            options={['GIFs', 'Stickers', 'Clips']}
            className={className}
            defaultIndex={contentTypes.indexOf(routerType)}
        />
    )
}
export default SearchToggle
