'use client'
import _PaginationCarousel from '@/components/pagination-carousel'
import { ComponentProps, useContext } from 'react'
import { SearchTypeContext } from '../search-type-context'

const PaginationCarousel = ({
    ...componentProps
}: Omit<ComponentProps<typeof _PaginationCarousel>, 'startIndex' | 'onChange'>) => {
    const { carouselIndex, setCarouselIndex } = useContext(SearchTypeContext)
    return (
        <_PaginationCarousel
            {...componentProps}
            startIndex={carouselIndex}
            onChange={(index) => {
                setCarouselIndex(index)
            }}
        />
    )
}
export default PaginationCarousel
